export class ServerResponseError extends Error {
  constructor(
    message: string,
    public readonly code: number,
  ) {
    super(message);
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = ServerResponseError.name; // stack traces display correctly now
  }
}
